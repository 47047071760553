/* Base Styles */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Hero Section */
.hero-section {
    position: relative;
    height: 50vh;
    background: center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hero-section h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.hero-section p {
    font-size: 18px;
    margin-bottom: 30px;
}

.consultation-button {
    background-color: #ff9900;
    color: #fff;
    padding: 14px 28px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.consultation-button:hover {
    background-color: #e68a00;
    transform: scale(1.05);
}

/* Categories Section */
.categories-section {
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    color: #800000;
    margin-bottom: 20px;
}

.section-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 40px;
}

.categories-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
.quote-section {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9; /* Light background for separation */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: left; /* Align content to the left */
}

.quote-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #660000; /* Consistent with other headings */
    margin-bottom: 10px;
}

.quote-details-list {
    list-style: none; /* Remove default bullet points */
    padding: 0;
    margin: 0;
}

.quote-details-list li {
    margin-bottom: 8px;
    font-size: 0.95rem; /* Slightly smaller font for details */
    color: #555; /* Subtle text color */
}

.request-quote-button {
    margin-top: 15px;
    background-color: #ff9900;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: block; /* Ensure button spans the width */
    width: fit-content;
}

.request-quote-button:hover {
    background-color: #e68a00;
    transform: scale(1.05); /* Slight scale effect on hover */
}

.category-button {
    padding: 12px 24px;
    background-color: #800000;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 12px rgba(0, 61, 128, 0.2);
}
.chemical-tile img.chemical-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px; /* Add spacing between image and text */
}

.chemical-header {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center content horizontally */
}
h3 {
    font-size: 1rem;
    margin: 10px 0 0; /* Add spacing above */
    color: #660000;
    text-align: center;
    word-wrap: break-word; /* Handle long names */
}
.category-button.active,
.category-button:hover {
    background-color: #002966;
    transform: translateY(-2px);
}

/* Main Content Section */
.main-content-section {
    padding: 60px 20px;
}

.main-content-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
}

.chemicals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.chemical-tile {
    background-color: #fff;
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column; /* Ensures items stack vertically */
    justify-content: space-between; /* Space between image and text */
    align-items: center; /* Center content horizontally */
    min-height: 250px; /* Set minimum height for consistency */
}

.chemical-tile:hover {
    border-color: #660000;
    transform: scale(1.05);
}

.chemical-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expand-button {
    background: #ff9900;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 10px;
    right: 10px;
}

.chemical-details {
    display: none;
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
}

.chemical-tile.expanded .chemical-details {
    display: block;
}

.chemical-details h4 {
    margin-bottom: 5px;
    font-size: 1.1rem;
    color: #660000;
}

.chemical-details ul {
    padding-left: 20px;
    margin: 10px 0;
}

.download-link {
    color: #800000;
    text-decoration: none;
    font-size: 14px;
    border-bottom: 1px solid #800000;
}

.download-link:hover {
    color: #660000;
    border-color: #660000;
}

/* Responsive Design */
@media (max-width: 768px) {
    .main-content-grid {
        grid-template-columns: 1fr;
    }

    .product-content {
        grid-template-columns: 1fr;
    }

    .chemical-tile {
        margin-bottom: 20px;
        
    }
    
    .chemical-header {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}
